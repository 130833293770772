import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>{t("not-found.title")}</h1>
      <p>{t("not-found.message")}</p>
    </Layout>
  );
};

export const query = graphql`
  query QueryI18($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
